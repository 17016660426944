<template>
  <v-container class="pa-1" style="max-height: 80vh">
    <!--    <v-row align="center" dense>
        <v-col cols="12" md="6">
          <v-text-field
            filled
            label="Order ID"
            v-model="orderId"
            dense
            clearable
            required
            :rules="[(v) => !!v || $t('msgRequiredField')]"
            @keydown.enter="getOrderById()"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn color="info" medium @click="getOrderById()">
            <v-icon left dark> mdi-magnify </v-icon>
            Search
          </v-btn>
        </v-col>
      </v-row> -->
    <v-row no-gutters>
      <v-col cols="12">
        <template>
          <v-chip
            dark
            label
            class="pa-1"
            @click="copyText(orderDetailData.id)"
            :title="$t('ConsoleOrderDetail_tooltip.copyToClipboard')"
          >
            {{ orderDetailData.id.slice(0, 8) }}
          </v-chip>
        </template>
        <template>
          <v-chip
            dark
            :color="getSourceColor(orderDetailData.source)"
            class="ml-1 pa-1"
            label
            :title="
              $t('source_tooltip.' + orderDetailData.source) +
              (orderDetailData.source == 'DISPATCH_TAXISERVICE'
                ? ' ' +
                  orderDetailData.userDispatcherName +
                  ' (id ' +
                  orderDetailData.userDispatcherId +
                  ')'
                : '')
            "
            v-show="orderDetailData.source != ''"
          >
            {{ $t("source." + orderDetailData.source) }}
          </v-chip>
        </template>
        <template>
          <v-chip
            dark
            :color="getTypeColor(orderDetailData.type)"
            class="ml-1 pa-1"
            label
            :title="$t('orderType_tooltip.' + orderDetailData.type)"
            v-show="orderDetailData.type != ''"
          >
            {{ $t("orderType." + orderDetailData.type) }}
          </v-chip>
        </template>
        <v-chip
          dark
          label
          class="ml-1 pa-1"
          :title="
            $t('ConsoleOrderDetail_tooltip.createdAtChip') +
            orderDetailData.createdAt
          "
          v-show="orderDetailData.createdAt != null"
          ><!-- {{ new Date(new Date(orderDetailData.createdAt) - new Date().getTimezoneOffset() * 60000) -->
          <!--{{ new Date(orderDetailData.createdAt)
                  .toISOString()
                  .substr(0, 16), }}-->
          {{ orderDetailData.createdAt | formatDateShort }}
        </v-chip>
        <!--{{ $t("orderStatus." + item.status) }}-->
        <template>
          <!-- <v-tooltip bottom>-->
          <!--<template v-slot:activator="{ on, attrs }"> //             v-on="on"-->
          <v-chip
            :class="
              getStatusColorDark(orderDetailData.status) == true
                ? 'ml-1 pa-1 white--text'
                : 'ml-1 pa-1 black--text'
            "
            :color="getStatusColor(orderDetailData.status)"
            label
            @click="
              orderDetailData.status == 'NEW'
                ? (directEditOrder = 'confirmNew')
                : orderDetailData.status == 'PLANNED'
                ? (directEditOrder = 'sendingActivate')
                : orderDetailData.status == 'PENDING'
                ? (directEditOrder = 'sendingDeactivate')
                : orderDetailData.status == 'ACCEPTED'
                ? (directEditOrder = 'abort')
                : orderDetailData.status == 'WAITING'
                ? (directEditOrder = 'abort')
                : orderDetailData.status == 'IN_PROGRESS'
                ? (directEditOrder = 'abort')
                : (directEditOrder = 'default'),
                incrementPassOrderEditTrigger()
            "
            v-show="orderDetailData.status != ''"
            :title="
              $t('orderStatus_tooltip.' + orderDetailData.status) +
              ' | ' +
              $t('ConsoleOrderDetail_tooltip.changeStatusAtChip')
            "
          >
            {{ $t("orderStatus." + orderDetailData.status) }}
          </v-chip>
          <!--</template>-->
          <!--<span>{{ $t("orderStatus_tooltip." + orderDetailData.status) }}</span>-->
          <!--</v-tooltip>-->
        </template>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <autocomplete-driver-remote
          v-model="driverModel"
          :label="$t('ConsoleOrderDetail.btnAutocompleteDriverRemote')"
          :placeholder="
            $t('ConsoleOrderDetail.btnAutocompleteDriverRemote_placeholder')
          "
          :title="$t('ConsoleOrderDetail_tooltip.btnAutocompleteDriverRemote')"
          :searchParams="driverSearchData"
          dense
          singleLine
          hideDetails
        ></autocomplete-driver-remote></v-col
    ></v-row>
    <v-row dense>
      <v-col cols="12" align-self="center">
        <v-btn
          color="info"
          small
          :title="$t('ConsoleOrderDetail_tooltip.btnSendOrderToDriver')"
          @click="sendOrderToDriverApi()"
          :disabled="buttonOrderSendDisable"
          ><v-icon dark :left="$vuetify.breakpoint.lgAndUp"> mdi-send </v-icon
          ><span v-show="$vuetify.breakpoint.lgAndUp">{{
            $t("ConsoleOrderDetail.btnSendOrderToDriver")
          }}</span></v-btn
        >
        <v-btn
          color="info"
          class="ml-1"
          small
          :title="$t('ConsoleOrderDetail_tooltip.btnUpdatePreferreDriver')"
          @click="updatePreferredDriverApi()"
          :disabled="buttonOrderPreferredDriverDisable"
          ><v-icon dark :left="$vuetify.breakpoint.lgAndUp">
            mdi-heart-outline </v-icon
          ><span v-show="$vuetify.breakpoint.lgAndUp">{{
            $t("ConsoleOrderDetail.btnUpdatePreferreDriver")
          }}</span></v-btn
        >
        <v-btn
          color="info"
          class="ml-1"
          small
          :title="
            $t('ConsoleOrderDetail_tooltip.btnShowEditDriverWorkShiftDialog')
          "
          @click="showEditDriverWorkShiftDialog()"
          ><v-icon dark :left="$vuetify.breakpoint.lgAndUp">
            mdi-briefcase-clock-outline </v-icon
          ><span v-show="$vuetify.breakpoint.lgAndUp">{{
            $t("ConsoleOrderDetail.btnShowEditDriverWorkShiftDialog")
          }}</span></v-btn
        >
        <!-- <v-btn
          color="info"
          class="ml-1"
          small
          :title="$t('ConsoleOrderDetail_tooltip.btnFindDriverOnMap')"
          @click="findDriverOnMap()"
          ><v-icon dark :left="$vuetify.breakpoint.lgAndUp">
            mdi-map-search </v-icon
          ><span v-show="$vuetify.breakpoint.lgAndUp">{{
            $t("ConsoleOrderDetail.btnFindDriverOnMap")
          }}</span></v-btn
        > -->
        <v-btn
          color="info"
          class="ml-1"
          small
          :title="$t('ConsoleOrderDetail_tooltip.btnFindDriverOnMap')"
          @click="findDriverOnMap()"
          ><v-icon dark> mdi-map-search </v-icon></v-btn
        >
        <v-btn
          color="info"
          :title="$t('ConsoleOrderDetail_tooltip.btnChangeDriverSilence')"
          class="ml-1"
          small
          @click="changeDriverSilence()"
          :disabled="!driverModel"
        >
          <v-icon dark>mdi-bell-cog</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <!-- tu mam problem, ze button v custom componente nechce byt v jednom riadku s ostatnymi, preto rozdelujem v-col-->
      <v-col cols="12" align-self="center">
        <v-btn
          color="info"
          :title="$t('ConsoleOrderDetail_tooltip.btnDetailRefresh')"
          class="mr-1"
          small
          @click="getOrderById(displayedOrderId)"
        >
          <v-icon dark>mdi-refresh</v-icon>
        </v-btn>
        <order-edit
          @showOrderDetailByIdFromEdit="showOrderDetailByIdFromEdit($event)"
          :orderIdParam="orderDetailData.id"
          :orderStatusParam="orderDetailData.status"
          :orderNoteParam="orderDetailData.note"
          :orderPresendTimeParam="orderDetailData.presendTime"
          :orderRequestedTimeParam="orderDetailData.requestedTime"
          :orderCreatedAtParam="orderDetailData.createdAt"
          :orderTypeParam="orderDetailData.type"
          :orderFilterParam="orderDetailData.filter"
          :orderSettingsParam="orderDetailSettings"
          :disabled="buttonOrderEditDisable"
          :directEdit="directEditOrder"
          :orderEditTrigger="passOrderEditTrigger"
        >
        </order-edit>
        <order-waypoint-edit
          @showOrderDetailByIdFromEdit="showOrderDetailByIdFromEdit($event)"
          :orderIdParam="orderDetailData.id"
          :orderStatusParam="orderDetailData.status"
          :orderCreatedAtParam="orderDetailData.createdAt"
          :disabled="buttonOrderWaypointEditDisable"
        >
        </order-waypoint-edit>
        <!-- </v-col>
      <v-col cols="12" md="6" align-self="center">-->
        <v-btn
          :color="
            orderDetailData.source == 'DISPATCH_BROKER' ||
            orderDetailData.source == 'DISPATCH_TAXXIMO' ||
            orderDetailData.source == 'DISPATCH_TAXISERVICE'
              ? 'info'
              : 'warning'
          "
          :title="$t('ConsoleOrderDetail_tooltip.btnShowMessage')"
          class="ml-1"
          small
          @click="
            showMessagefromOrderDetail(displayedOrderId),
              notifyMessageStatus(orderDetailData.status)
          "
          :disabled="buttonOrderChatDisable"
        >
          <v-icon
            dark
            :color="
              orderDetailData.status == 'FINISHED' ||
              orderDetailData.status == 'CHARGED' ||
              orderDetailData.status == 'SETTLED' ||
              orderDetailData.status == 'ARCHIVED' ||
              orderDetailData.status == 'CANCELLED_PREREGISTERED' ||
              orderDetailData.status == 'CANCELLED_NEW' ||
              orderDetailData.status == 'CANCELLED_PLANNED' ||
              orderDetailData.status == 'CANCELLED_PENDING' ||
              orderDetailData.status == 'CANCELLED_ACCEPTED' ||
              orderDetailData.status == 'CANCELLED_WAITING' ||
              orderDetailData.status == 'CANCELLED_IN_PROGRESS' ||
              orderDetailData.status == 'CANCELLED_FINISHED' ||
              orderDetailData.status == 'CANCELLED_CHARGED' ||
              orderDetailData.status == 'CANCELLED_SETTLED'
                ? 'grey'
                : ''
            "
            >mdi-comment-text-multiple-outline</v-icon
          >
        </v-btn>
        <v-btn
          color="info"
          class="ml-1 px-0"
          small
          center
          :title="$t('ConsoleOrderDetail_tooltip.phoneClientCopy')"
          @click="copyText(orderDetailData.client.phone)"
          ><v-icon dark small class="mx-0 px-0">mdi-content-copy</v-icon
          ><v-icon dark small class="mx-0 px-0">mdi-human-greeting</v-icon
          ><v-icon dark small class="mx-0 px-0">mdi-phone-settings</v-icon>
        </v-btn>
        <v-btn
          color="info"
          class="ml-1 px-0"
          small
          center
          :title="$t('ConsoleOrderDetail_tooltip.phoneDriverCopy')"
          @click="copyText(orderDetailData.driver.phone)"
          :disabled="buttonOrderDriverPhoneDisable"
          ><v-icon dark small class="mx-0 px-0">mdi-content-copy</v-icon
          ><v-icon dark small class="mx-0 px-0">fas fa-user-tie</v-icon
          ><v-icon dark small class="mx-0 px-0">mdi-phone-settings</v-icon>
        </v-btn>
        <v-btn
          color="info"
          class="ml-1 px-0"
          small
          center
          :title="$t('ConsoleOrderDetail_tooltip.phonePbxCall')"
          @click="confirmPbxCallDialog = true"
        >
          <!--  @click="pbxCall(orderDetailData.client.phone)" -->
          <!-- <v-icon dark small class="mx-0 px-0">mdi-account-switch-outline</v-icon> -->
          <v-icon dark small class="mx-0 px-0"
            >mdi-phone-incoming-outgoing</v-icon
          >
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="6">
        <span>
          <v-icon
            :title="$t('ConsoleOrderDetail_tooltip.requestedTime')"
            :color="
              orderDetailData.type == 'TIME'
                ? getTypeColor(orderDetailData.type)
                : 'gray'
            "
            @click="
              (directEditOrder = 'requestedTime'),
                incrementPassOrderEditTrigger()
            "
            >far fa-clock</v-icon
          >
          <!--<v-icon>far fa-clock</v-icon>-->
          {{ $t("ConsoleOrderDetail.requestedTime") }}
          <!--{{ new Date(new Date(orderDetailData.requestedTime) - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 16), }}-->
          <b>{{ orderDetailData.requestedTime | formatDateShort }}</b>
        </span>
      </v-col>
      <v-col cols="12" md="6">
        <span>
          <v-icon
            :title="$t('ConsoleOrderDetail_tooltip.presendTime')"
            :color="
              orderDetailData.type == 'TIME'
                ? getTypeColor(orderDetailData.type)
                : 'gray'
            "
            @click="
              (directEditOrder = 'presendTime'), incrementPassOrderEditTrigger()
            "
            >fas fa-hourglass-half</v-icon
          >
          {{ $t("ConsoleOrderDetail.presendTime") }}
          <b>{{ orderDetailData.presendTime }} min.</b></span
        >
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="6">
        <span>
          <v-icon
            :title="$t('ConsoleOrderDetail_tooltip.paymentType')"
            @click="
              (directEditOrder = 'payment'), incrementPassOrderEditTrigger()
            "
            >fas fa-money-bill-alt</v-icon
          >
          {{ $t("ConsoleOrderDetail.paymentType") }}
          <b>{{ $t("paymentType." + orderDetailData.paymentType) }}</b></span
        >
      </v-col>
      <v-col cols="12" md="6">
        <span v-if="orderDetailData.account">
          <v-icon :title="$t('ConsoleOrderDetail_tooltip.account')"
            >mdi-briefcase-variant</v-icon
          >
          <!--{{ $t("ConsoleOrderDetail.account") }}-->
          {{ orderDetailData.account.id }} -
          {{ orderDetailData.account.name }}
        </span>
      </v-col>
    </v-row>
    <v-divider :inset="false"></v-divider>
    <v-list>
      <template v-for="(waypoint, index2) in orderDetailWaypointList">
        <v-btn
          icon
          :key="'B' + waypoint.id"
          @click="findPositionOnMap(waypoint.latitude, waypoint.longitude)"
          title="Zobrazit adresu na mape"
        >
          <v-icon
            :key="waypoint.id"
            :color="waypoint.status == 'COMPLETED' ? 'green' : 'gray'"
            >{{ addressIcons[waypoint.type] }}</v-icon
          ></v-btn
        >
        <span
          :key="'W' + waypoint.id"
          class="d-inline-block"
          :style="[
            waypoint.status == 'DISABLED'
              ? { 'max-width': '420px', 'text-decoration': 'line-through' }
              : { 'max-width': '420px' },
            waypoint.type == 'BOARD' || waypoint.type == 'DESTINATION'
              ? { 'font-weight': 'bold' }
              : {},
          ]"
          >{{ waypoint.address }}</span
        >
        <v-divider
          v-if="index2 < orderDetailData.waypointList.length - 1"
          :key="index2"
          :inset="true"
        ></v-divider>
      </template>
    </v-list>
    <v-divider :inset="false"></v-divider>
    <v-row dense>
      <v-col cols="12">
        <span>
          <v-icon
            :title="$t('ConsoleOrderDetail_tooltip.note')"
            @click="(directEditOrder = 'note'), incrementPassOrderEditTrigger()"
            >far fa-file-alt</v-icon
          >
          {{ $t("ConsoleOrderDetail.note") }}
          {{ orderDetailData.note }}
        </span>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-expansion-panels>
          <!--PANEL ZAKAZNIK-->
          <v-expansion-panel>
            <v-expansion-panel-header class="py-0"
              ><div>
                <v-icon :title="$t('ConsoleOrderDetail_tooltip.client')"
                  >mdi-human-greeting</v-icon
                >
                {{
                  !orderDetailData.client
                    ? $t("ConsoleOrderDetail.clientNone")
                    : ""
                }}
                {{
                  orderDetailData.client
                    ? !orderDetailData.client.id
                      ? $t("ConsoleOrderDetail.clientNone")
                      : ""
                    : ""
                }}

                {{
                  orderDetailData.client
                    ? orderDetailData.client.nickname
                      ? orderDetailData.client.nickname
                      : ""
                    : ""
                }}

                {{
                  orderDetailData.client
                    ? orderDetailData.client.mark
                      ? " | " + $t("clientMark." + orderDetailData.client.mark)
                      : ""
                    : ""
                }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon
                      :title="$t('ConsoleOrderDetail_tooltip.nicknameClient')"
                      >mdi-card-account-details-outline</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.nicknameClient") }}
                    {{
                      orderDetailData.client
                        ? orderDetailData.client.nickname
                          ? orderDetailData.client.nickname
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon
                      :title="$t('ConsoleOrderDetail_tooltip.phoneClientCopy')"
                      @click="copyText(orderDetailData.client.phone)"
                      >mdi-phone</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.phoneClient") }}
                    {{
                      orderDetailData.client
                        ? orderDetailData.client.phone
                          ? orderDetailData.client.phone
                          : ""
                        : ""
                    }}
                    <a
                      style="text-decoration: none; color: inherit"
                      :href="`tel:${
                        orderDetailData.client
                          ? orderDetailData.client.phone
                          : ''
                      }`"
                    >
                      <v-icon
                        small
                        :title="
                          $t('ConsoleOrderDetail_tooltip.phoneClientHref')
                        "
                        >mdi-link-box-variant-outline</v-icon
                      >
                    </a>
                  </span>
                </v-col> </v-row
              ><v-row no-gutters>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon :title="$t('ConsoleOrderDetail_tooltip.mark')"
                      >mdi-bookmark-multiple-outline</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.mark") }}
                    {{
                      orderDetailData.client
                        ? orderDetailData.client.mark
                          ? $t("clientMark." + orderDetailData.client.mark)
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon
                      :title="$t('ConsoleOrderDetail_tooltip.ratingClient')"
                      >mdi-star</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.ratingClient") }}
                    {{
                      orderDetailData.client
                        ? orderDetailData.client.rating
                          ? orderDetailData.client.rating
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <span>
                    <v-icon :title="$t('ConsoleOrderDetail_tooltip.noteClient')"
                      >mdi-note-text-outline</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.noteClient") }}
                    {{
                      orderDetailData.client
                        ? orderDetailData.client.note
                          ? orderDetailData.client.note
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn
                    text
                    x-small
                    color="info"
                    @click="openHistoryClientDialog()"
                    :title="
                      $t('ConsoleOrderDetail.btnOpenHistoryClientDialog_title')
                    "
                    :disabled="
                      !orderDetailData.client ||
                      orderDetailData.source !== 'DISPATCH_TAXISERVICE'
                    "
                  >
                    <v-icon left> mdi-view-list-outline </v-icon>
                    {{
                      $t("ConsoleOrderDetail.btnOpenHistoryClientDialog")
                    }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!--PANEL VODIC-->
          <v-expansion-panel>
            <v-expansion-panel-header class="py-0">
              <div>
                <v-icon :title="$t('ConsoleOrderDetail_tooltip.driver')"
                  >fas fa-user-tie</v-icon
                >
                {{
                  !orderDetailData.driver
                    ? $t("ConsoleOrderDetail.driverNone")
                    : ""
                }}
                {{
                  orderDetailData.driver
                    ? !orderDetailData.driver.id
                      ? $t("ConsoleOrderDetail.driverNone")
                      : ""
                    : ""
                }}
                {{
                  orderDetailData.driver
                    ? orderDetailData.driver.firstName
                      ? orderDetailData.driver.firstName
                      : ""
                    : ""
                }}
                {{
                  orderDetailData.driver
                    ? orderDetailData.driver.lastName
                      ? orderDetailData.driver.lastName
                      : ""
                    : ""
                }}

                {{
                  orderDetailData.vehicle
                    ? orderDetailData.vehicle.id
                      ? " | "
                      : ""
                    : ""
                }}
                {{
                  orderDetailData.vehicle
                    ? orderDetailData.vehicle.internalName
                      ? orderDetailData.vehicle.internalName
                      : ""
                    : ""
                }}
                {{
                  orderDetailData.vehicle
                    ? orderDetailData.vehicle.brand
                      ? orderDetailData.vehicle.brand
                      : ""
                    : ""
                }}
                {{
                  orderDetailData.vehicle
                    ? orderDetailData.vehicle.model
                      ? orderDetailData.vehicle.model
                      : ""
                    : ""
                }}
                {{
                  orderDetailData.preferredDriver
                    ? orderDetailData.preferredDriver.id
                      ? " | &#9825;"
                      : ""
                    : ""
                }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters v-show="displayOnlyForAdmin">
                <v-col cols="12" md="6">
                  <span>
                    <v-icon :title="$t('ConsoleOrderDetail_tooltip.driverId')"
                      >far fa-file-alt</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.driverId") }}
                    {{
                      orderDetailData.driver
                        ? orderDetailData.driver.id
                          ? orderDetailData.driver.id
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon :title="$t('ConsoleOrderDetail_tooltip.vehicleId')"
                      >far fa-file-alt</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.vehicleId") }}
                    {{
                      orderDetailData.vehicle
                        ? orderDetailData.vehicle.id
                          ? orderDetailData.vehicle.id
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon :title="$t('ConsoleOrderDetail_tooltip.firstName')"
                      >mdi-card-account-details-outline</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.firstName") }}
                    {{
                      orderDetailData.driver
                        ? orderDetailData.driver.firstName
                          ? orderDetailData.driver.firstName
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
                <v-col cols="12" md="6">
                  <span :title="$t('ConsoleOrderDetail_tooltip.lastName')">
                    {{ $t("ConsoleOrderDetail.lastName") }}
                    {{
                      orderDetailData.driver
                        ? orderDetailData.driver.lastName
                          ? orderDetailData.driver.lastName
                          : ""
                        : ""
                    }}
                  </span>
                </v-col> </v-row
              ><v-row no-gutters>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon
                      :title="$t('ConsoleOrderDetail_tooltip.phoneDriver')"
                      @click="
                        copyText(
                          orderDetailData.driver
                            ? orderDetailData.driver.phone
                              ? orderDetailData.driver.phone
                              : ''
                            : ''
                        )
                      "
                      >mdi-phone</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.phoneDriver") }}
                    {{
                      orderDetailData.driver
                        ? orderDetailData.driver.phone
                          ? orderDetailData.driver.phone
                          : ""
                        : ""
                    }}
                    <a
                      style="text-decoration: none; color: inherit"
                      :href="`tel:${
                        orderDetailData.driver
                          ? orderDetailData.driver.phone
                          : ''
                      }`"
                    >
                      <v-icon
                        small
                        :title="
                          $t('ConsoleOrderDetail_tooltip.phoneDriverHref')
                        "
                        >mdi-link-box-variant-outline</v-icon
                      >
                    </a>
                  </span>
                </v-col>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon
                      :title="$t('ConsoleOrderDetail_tooltip.nicknameDriver')"
                      >mdi-shield-account-outline</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.nicknameDriver") }}
                    {{
                      orderDetailData.driver
                        ? orderDetailData.driver.nickname
                          ? orderDetailData.driver.nickname
                          : ""
                        : ""
                    }}
                  </span>
                </v-col> </v-row
              ><v-row no-gutters>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon
                      :title="$t('ConsoleOrderDetail_tooltip.driveCounter')"
                      >mdi-counter</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.driveCounter") }}
                    {{
                      orderDetailData.driver
                        ? orderDetailData.driver.driveCounter
                          ? orderDetailData.driver.driveCounter
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon
                      :title="$t('ConsoleOrderDetail_tooltip.ratingDriver')"
                      >mdi-star</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.ratingDriver") }}
                    {{
                      orderDetailData.driver
                        ? orderDetailData.driver.rating
                          ? orderDetailData.driver.rating
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon :title="$t('ConsoleOrderDetail_tooltip.brand')"
                      >mdi-car-info</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.brand") }}
                    {{
                      orderDetailData.vehicle
                        ? orderDetailData.vehicle.brand
                          ? orderDetailData.vehicle.brand
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
                <v-col cols="12" md="6">
                  <span :title="$t('ConsoleOrderDetail_tooltip.model')">
                    {{ $t("ConsoleOrderDetail.model") }}
                    {{
                      orderDetailData.vehicle
                        ? orderDetailData.vehicle.model
                          ? orderDetailData.vehicle.model
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon
                      :title="$t('ConsoleOrderDetail_tooltip.internalName')"
                      >mdi-map-marker-circle</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.internalName") }}
                    {{
                      orderDetailData.vehicle
                        ? orderDetailData.vehicle.internalName
                          ? orderDetailData.vehicle.internalName
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon :title="$t('ConsoleOrderDetail_tooltip.lpn')"
                      >mdi-card-outline</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.lpn") }}
                    {{
                      orderDetailData.vehicle
                        ? orderDetailData.vehicle.lpn
                          ? orderDetailData.vehicle.lpn
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon
                      :title="$t('ConsoleOrderDetail_tooltip.vehicleType')"
                      >mdi-train-car</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.vehicleType") }}
                    {{
                      orderDetailData.vehicle
                        ? orderDetailData.vehicle.type
                          ? orderDetailData.vehicle.type
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon :title="$t('ConsoleOrderDetail_tooltip.color')"
                      >mdi-palette</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.color") }}
                    {{
                      orderDetailData.vehicle
                        ? orderDetailData.vehicle.color
                          ? orderDetailData.vehicle.color
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <span>
                    <v-icon
                      :title="
                        $t('ConsoleOrderDetail_tooltip.preferredDriverLastName')
                      "
                      >mdi-account-heart-outline</v-icon
                    >
                    {{ $t("ConsoleOrderDetail.preferredDriverFirstName") }}
                    {{
                      orderDetailData.preferredDriver
                        ? orderDetailData.preferredDriver.firstName
                          ? orderDetailData.preferredDriver.firstName
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
                <v-col cols="12" md="6">
                  <span
                    :title="
                      $t('ConsoleOrderDetail_tooltip.preferredDriverLastName')
                    "
                  >
                    {{ $t("ConsoleOrderDetail.preferredDriverLastName") }}
                    {{
                      orderDetailData.preferredDriver
                        ? orderDetailData.preferredDriver.lastName
                          ? orderDetailData.preferredDriver.lastName
                          : ""
                        : ""
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!--PANEL POZIADAVKY-->
          <v-expansion-panel>
            <v-expansion-panel-header class="py-0"
              ><div>
                <v-icon :title="$t('ConsoleOrderDetail_tooltip.orderFilter')"
                  >mdi-filter-cog-outline</v-icon
                >
                {{ $t("ConsoleOrderDetail.orderFilter") }}
                {{ orderDetailData.filter.animal != "ANY" ? " | &#129409;" : ""
                }}{{
                  orderDetailData.filter.animal == "NONE"
                    ? "X"
                    : orderDetailData.filter.animal == "SMALL"
                    ? "S "
                    : orderDetailData.filter.animal == "MEDIUM"
                    ? "M"
                    : orderDetailData.filter.animal == "BIG"
                    ? "L"
                    : ""
                }}
                {{
                  orderDetailData.filter.luggage != "ANY" ? " | &#128708;" : ""
                }}{{
                  orderDetailData.filter.luggage == "SMALL"
                    ? "S "
                    : orderDetailData.filter.luggage == "MEDIUM"
                    ? "M"
                    : orderDetailData.filter.luggage == "BIG"
                    ? "L"
                    : ""
                }}

                {{
                  orderDetailData.filter.specialTransport != "ANY"
                    ? " | &#9855;"
                    : ""
                }}
                {{
                  orderDetailData.filter.childrenChair != "ANY"
                    ? " | &#128700;"
                    : ""
                }}
                {{
                  orderDetailData.filter.maxPerson > 0
                    ? " | #" + orderDetailData.filter.maxPerson
                    : ""
                }}
                {{
                  orderDetailData.filter.paymentTerminal != "ANY"
                    ? " | &#128179;"
                    : ""
                }}
              </div></v-expansion-panel-header
            >

            <v-expansion-panel-content>
              <v-row no-gutters>
                <!--<v-col cols="12" md="6">
                  <v-select
                    :label="$t('orderFilter.airconditioning')"
                    dense
                    readonly
                    append-icon=""
                    v-model="orderDetailData.filter.airconditioning"
                    :items="$t('orderFilterSelect.airconditioning')"
                  ></v-select>
                </v-col>-->
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('orderFilter.animal')"
                    dense
                    readonly
                    append-icon=""
                    v-model="orderDetailData.filter.animal"
                    :items="$t('orderFilterSelect.animal')"
                    :success="
                      orderDetailData.filter.animal &&
                      orderDetailData.filter.animal !== 'ANY'
                        ? true
                        : false
                    "
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('orderFilter.luggage')"
                    dense
                    readonly
                    append-icon=""
                    v-model="orderDetailData.filter.luggage"
                    :items="$t('orderFilterSelect.luggage')"
                    :success="
                      orderDetailData.filter.luggage &&
                      orderDetailData.filter.luggage !== 'ANY'
                        ? true
                        : false
                    "
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <!--<v-col cols="12" md="6">
                  <v-select
                    :label="$t('orderFilter.autonomous')"
                    dense
                    readonly
                    append-icon=""
                    v-model="orderDetailData.filter.autonomous"
                    :items="$t('orderFilterSelect.autonomous')"
                  ></v-select>
                </v-col>-->
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('orderFilter.paymentTerminal')"
                    dense
                    readonly
                    append-icon=""
                    v-model="orderDetailData.filter.paymentTerminal"
                    :items="$t('orderFilterSelect.paymentTerminal')"
                    :success="
                      orderDetailData.filter.paymentTerminal &&
                      orderDetailData.filter.paymentTerminal !== 'ANY'
                        ? true
                        : false
                    "
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('orderFilter.childrenChair')"
                    dense
                    readonly
                    append-icon=""
                    v-model="orderDetailData.filter.childrenChair"
                    :items="$t('orderFilterSelect.childrenChair')"
                    :success="
                      orderDetailData.filter.childrenChair &&
                      orderDetailData.filter.childrenChair !== 'ANY'
                        ? true
                        : false
                    "
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <!--<v-col cols="12" md="6">
                  <v-select
                    :label="$t('orderFilter.eco')"
                    dense
                    readonly
                    append-icon=""
                    v-model="orderDetailData.filter.eco"
                    :items="$t('orderFilterSelect.eco')"
                  ></v-select>
                </v-col>-->
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('orderFilter.maxPerson')"
                    dense
                    readonly
                    append-icon=""
                    v-model="orderDetailData.filter.maxPerson"
                    :items="$t('orderFilterSelect.maxPerson')"
                    :success="
                      orderDetailData.filter.maxPerson &&
                      orderDetailData.filter.maxPerson > 0
                        ? true
                        : false
                    "
                  ></v-select>
                </v-col>
                <!--<v-col cols="12" md="6">
                  <v-select
                    :label="$t('orderFilter.smoking')"
                    dense
                    readonly
                    append-icon=""
                    v-model="orderDetailData.filter.smoking"
                    :items="$t('orderFilterSelect.smoking')"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters>-->
                <v-col cols="12" md="6">
                  <v-select
                    :label="$t('orderFilter.specialTransport')"
                    dense
                    readonly
                    append-icon=""
                    v-model="orderDetailData.filter.specialTransport"
                    :items="$t('orderFilterSelect.specialTransport')"
                    :success="
                      orderDetailData.filter.specialTransport &&
                      orderDetailData.filter.specialTransport !== 'ANY'
                        ? true
                        : false
                    "
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    text
                    x-small
                    color="info"
                    @click="
                      (directEditOrder = 'filter'),
                        incrementPassOrderEditTrigger()
                    "
                  >
                    <v-icon left> mdi-pencil </v-icon>
                    {{ $t("btnEdit") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!--PANEL ZOBRAZENIE-->
          <v-expansion-panel>
            <v-expansion-panel-header class="py-0">
              <div>
                <v-icon :title="$t('ConsoleOrderDetail_tooltip.displayAndSend')"
                  >mdi-cellphone-cog</v-icon
                >
                {{ $t("ConsoleOrderDetail.displayAndSend") }}
                {{ orderDetailData.showPrice == "ALL" ? " | €$¥ " : " " }}
                {{
                  orderDetailData.showPrice == "ONLY_DRIVER" ? " | €$ " : " "
                }}
                {{ orderDetailData.showPhone == true ? " | \u260F " : " " }}
                {{
                  orderDetailData.showAllAddresses == true ? " | \u2690 " : " "
                }}
                {{
                  orderDetailData.onlyManualSending == true ? " | \u26A0 " : " "
                }}
                {{
                  orderDetailData.forcedPickupRadius > 0 ? " | \u2609 " : " "
                }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider :inset="false" class="py-3"></v-divider>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-select
                    :label="$t('ConsoleOrderDetail.showPrice')"
                    dense
                    readonly
                    prepend-icon="mdi-cash-multiple"
                    v-model="orderDetailData.showPrice"
                    :items="$t('showPriceArray')"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :label="$t('ConsoleOrderDetail.showDriverPhoneButton')"
                    dense
                    readonly
                    prepend-icon="mdi-phone-dial-outline"
                    v-model="orderDetailData.showDriverPhoneButton"
                    :items="$t('showDriverPhoneButtonArray')"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    :label="$t('ConsoleOrderDetail.showPhone') + ' \u260F'"
                    dense
                    readonly
                    append-icon=""
                    v-model="orderDetailData.showPhone"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    :label="
                      $t('ConsoleOrderDetail.showAllAddresses') + ' \u2690'
                    "
                    dense
                    readonly
                    append-icon=""
                    v-model="orderDetailData.showAllAddresses"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    :label="
                      $t('ConsoleOrderDetail.onlyManualSending') + ' \u26A0'
                    "
                    dense
                    readonly
                    append-icon=""
                    v-model="orderDetailData.onlyManualSending"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="
                      $t('ConsoleOrderDetail.forcedPickupRadius') + ' \u2609'
                    "
                    dense
                    readonly
                    prepend-icon="mdi-map-marker-radius-outline"
                    v-model="orderDetailData.forcedPickupRadius"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    text
                    x-small
                    color="info"
                    @click="
                      (directEditOrder = 'settings'),
                        incrementPassOrderEditTrigger()
                    "
                  >
                    <v-icon left> mdi-pencil </v-icon>
                    {{ $t("btnEdit") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!--PANEL CENA-->
          <v-expansion-panel>
            <v-expansion-panel-header class="py-0">
              <div>
                <v-icon :title="$t('ConsoleOrderDetail_tooltip.price')"
                  >mdi-cash-multiple</v-icon
                >
                {{ $t("ConsoleOrderDetail.price") }}
                {{
                  orderDetailData.estimatedPriceTotal
                    ? orderDetailData.estimatedPriceTotal +
                      (orderDetailData.currency
                        ? orderDetailData.currency
                        : " ")
                    : " "
                }}
                {{
                  orderDetailData.finalPriceTotal
                    ? " | " +
                      orderDetailData.finalPriceTotal +
                      (orderDetailData.currency
                        ? orderDetailData.currency
                        : " ")
                    : " "
                }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("ConsoleOrderDetail.priceName") }}
                    </th>
                    <th class="text-center">
                      {{ $t("ConsoleOrderDetail.priceEstimated") }}
                    </th>
                    <th class="text-center">
                      {{ $t("ConsoleOrderDetail.priceFinal") }}
                    </th>
                    <th class="text-center">
                      {{ $t("ConsoleOrderDetail.priceInfo") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ $t("ConsoleOrderDetail.distance") }}</td>
                    <td class="text-right">
                      <!-- round to 3 decimal places -->
                      {{ orderDetailData.estimatedDistance }}
                      {{ orderDetailData.estimatedDistance ? "m" : "" }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.finalDistance }}
                      {{ orderDetailData.finalDistance ? "m" : "" }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.infoDistance }}
                      {{ orderDetailData.infoDistance ? "m" : "" }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("ConsoleOrderDetail.time") }}</td>
                    <td class="text-right">
                      {{ orderDetailData.estimatedTime }}
                      <!-- convert orderDetailData.estimatedTime value in seconds to minutes -->
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.finalTime }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.infoTime }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("ConsoleOrderDetail.priceStart") }}
                    </td>
                    <td class="text-right">
                      <b>{{ orderDetailData.estimatedPriceStart }}</b>
                    </td>
                    <td class="text-right">
                      <b>{{ orderDetailData.finalPriceStart }}</b>
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.infoPriceStart }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("ConsoleOrderDetail.priceWaiting") }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.estimatedPriceWaiting }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.finalPriceWaiting }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.infoPriceWaiting }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("ConsoleOrderDetail.priceDriveDistance") }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.estimatedPriceDriveDistance }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.finalPriceDriveDistance }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.infoPriceDriveDistance }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("ConsoleOrderDetail.priceDriveTime") }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.estimatedPriceDriveTime }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.finalPriceDriveTime }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.infoPriceDriveTime }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("ConsoleOrderDetail.priceDrive") }}
                    </td>
                    <td class="text-right">
                      <b>{{ orderDetailData.estimatedPriceDrive }}</b>
                    </td>
                    <td class="text-right">
                      <b>{{ orderDetailData.finalPriceDrive }}</b>
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.infoPriceDrive }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("ConsoleOrderDetail.priceJourney") }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.estimatedPriceJourney }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.finalPriceJourney }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.infoPriceJourney }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("ConsoleOrderDetail.priceExtra") }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.estimatedPriceExtra }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.finalPriceExtra }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.infoPriceExtra }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("ConsoleOrderDetail.priceDiscount") }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.estimatedPriceDiscount }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.finalPriceDiscount }}
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.infoPriceDiscount }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>{{ $t("ConsoleOrderDetail.priceTotal") }}</b>
                    </td>
                    <td class="text-right">
                      <b>{{ orderDetailData.estimatedPriceTotal }}</b>
                    </td>
                    <td class="text-right">
                      <b>{{ orderDetailData.finalPriceTotal }}</b>
                    </td>
                    <td class="text-right">
                      {{ orderDetailData.infoPriceTotal }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!--PANEL CAS-->
          <v-expansion-panel>
            <v-expansion-panel-header class="py-0">
              <div>
                <v-icon :title="$t('ConsoleOrderDetail_tooltip.timeChanged')"
                  >mdi-timeline-clock-outline</v-icon
                >
                {{ $t("ConsoleOrderDetail.timeChanged") }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderDetail.plannedAt')"
                    :title="$t('ConsoleOrderDetail_tooltip.plannedAt')"
                    dense
                    readonly
                    v-model="orderDetailData.plannedAt"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderDetail.pendingAt')"
                    :title="$t('ConsoleOrderDetail_tooltip.pendingAt')"
                    dense
                    readonly
                    v-model="orderDetailData.pendingAt"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderDetail.acceptedAt')"
                    :title="$t('ConsoleOrderDetail_tooltip.acceptedAt')"
                    dense
                    readonly
                    v-model="orderDetailData.acceptedAt"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="orderDetailData.waitingAt"
                    :label="$t('ConsoleOrderDetail.waitingAt')"
                    :title="$t('ConsoleOrderDetail_tooltip.waitingAt')"
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderDetail.progressAt')"
                    :title="$t('ConsoleOrderDetail_tooltip.progressAt')"
                    dense
                    readonly
                    v-model="orderDetailData.progressAt"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderDetail.finishedAt')"
                    :title="$t('ConsoleOrderDetail_tooltip.finishedAt')"
                    dense
                    readonly
                    v-model="orderDetailData.finishedAt"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderDetail.chargedAt')"
                    :title="$t('ConsoleOrderDetail_tooltip.chargedAt')"
                    dense
                    readonly
                    v-model="orderDetailData.chargedAt"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderDetail.settledAt')"
                    :title="$t('ConsoleOrderDetail_tooltip.settledAt')"
                    dense
                    readonly
                    v-model="orderDetailData.settledAt"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderDetail.interruptedAt')"
                    :title="$t('ConsoleOrderDetail_tooltip.interruptedAt')"
                    dense
                    readonly
                    v-model="orderDetailData.interruptedAt"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="$t('ConsoleOrderDetail.archivedAt')"
                    :title="$t('ConsoleOrderDetail_tooltip.archivedAt')"
                    dense
                    readonly
                    v-model="orderDetailData.archivedAt"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!--PANEL ZASIELANIE AUTOMATOM-->
          <v-expansion-panel>
            <v-expansion-panel-header class="py-0">
              <div>
                <v-icon
                  :title="$t('ConsoleOrderDetail_tooltip.sentOrderLogList')"
                  >mdi-send</v-icon
                >
                {{ $t("ConsoleOrderDetail.sentOrderLogList") }}
                {{
                  orderDetailData.orderSentLogs.length > 0
                    ? "(" + orderDetailData.orderSentLogs.length + ")"
                    : ""
                }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- v-simple-table z dat orderDetailData.orderSentLogs (driverId, fromBy, distance, createdAt) -->
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{ $t("ConsoleOrderDetail.tbl_driver") }}
                      </th>
                      <th
                        v-if="$vuetify.breakpoint.lgAndUp"
                        class="text-center"
                      >
                        {{ $t("ConsoleOrderDetail.tbl_sent") }}
                      </th>
                      <th v-else class="text-center">
                        {{ $t("ConsoleOrderDetail.tbl_sent_short") }}
                      </th>
                      <th
                        v-if="$vuetify.breakpoint.lgAndUp"
                        class="text-center"
                      >
                        {{ $t("ConsoleOrderDetail.tbl_distance") }}
                      </th>
                      <th v-else class="text-center">
                        {{ $t("ConsoleOrderDetail.tbl_distance_short") }}
                      </th>
                      <th class="text-left">
                        {{ $t("ConsoleOrderDetail.tbl_time") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in orderDetailData.orderSentLogs"
                      :key="item.id"
                    >
                      <td class="px-0">
                        {{
                          displayOnlyForAdmin && item.driverId
                            ? item.driverId
                            : ""
                        }}
                        {{ item.firstName ? item.firstName : "" }}
                        {{ item.lastName ? item.lastName : "" }}
                      </td>
                      <td class="px-0 text-center">
                        {{ item.fromBy ? item.fromBy : "" }}
                      </td>
                      <td class="px-0 text-center">
                        {{ item.distance ? item.distance + "m" : "" }}
                      </td>
                      <td class="px-0">
                        {{ item.createdAt | formatDateLong }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <!-- Dialog na schvaľovanie stavov pracovnej zmeny-->
    <v-dialog
      v-model="editDriverWorkShiftDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>{{
          $t("ConsoleOrderDetail.editDriverWorkShiftDialog_title")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="editDriverWorkShiftForm">
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-list dense>
                    <v-subheader v-show="!disableDriverWorkShiftBreak">{{
                      $t("ConsoleOrderDetail.workBreak")
                    }}</v-subheader>
                    <v-list-item
                      :disabled="disableDriverWorkShiftBreak"
                      v-show="!disableDriverWorkShiftBreak"
                      @click="
                        (editDriverWorkShiftDialog = false),
                          updateDriverWorkShift('breakShiftApprove')
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="success">mdi-coffee-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderDetail.workBreakApprove")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      :disabled="disableDriverWorkShiftBreak"
                      v-show="!disableDriverWorkShiftBreak"
                      @click="
                        (editDriverWorkShiftDialog = false),
                          updateDriverWorkShift('breakShiftReject')
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="error">mdi-coffee-off-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderDetail.workBreakReject")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-subheader v-show="!disableDriverWorkShiftEnd">{{
                      $t("ConsoleOrderDetail.workEndShift")
                    }}</v-subheader>
                    <v-list-item
                      :disabled="disableDriverWorkShiftEnd"
                      v-show="!disableDriverWorkShiftEnd"
                      @click="
                        (editDriverWorkShiftDialog = false),
                          updateDriverWorkShift('endShiftApprove')
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="success">mdi-home-import-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderDetail.workEndShiftApprove")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      :disabled="disableDriverWorkShiftEnd"
                      v-show="!disableDriverWorkShiftEnd"
                      @click="
                        (editDriverWorkShiftDialog = false),
                          updateDriverWorkShift('endShiftReject')
                      "
                    >
                      <v-list-item-icon>
                        <v-icon color="error">mdi-home-remove-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("ConsoleOrderDetail.workEndShiftReject")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click.stop="editDriverWorkShiftDialog = false"
              >{{ $t("btnClose") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Dialog so zoznamom otvorenych poziadaviek na pracovnu zmenu-->
    <v-dialog
      v-model="workShiftRequestListDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>{{
          $t("ConsoleOrderDetail.workShiftRequestListDialog_title")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in worklogRequestList"
                :key="i"
                @click="
                  driverModel = item.driverId;
                  workShiftRequestListDialog = false;
                "
              >
                <!--<v-list-item
                v-for="(item, i) in worklogRequestList"
                :key="i"
                @click="
                  driverModel = item.driverId;
                  workShiftRequestListDialog = false;
                  showEditDriverWorkShiftDialog();
                "
              >
                <v-list-item-icon>
                  <v-icon>mdi-information-outline</v-icon>
                </v-list-item-icon>-->
                <v-list-item-avatar>
                  <v-icon
                    :color="
                      item.status == 'NEW'
                        ? 'orange'
                        : item.status == 'CONFIRMED'
                        ? 'green'
                        : 'grey'
                    "
                    >{{
                      item.type == "ON_BREAK"
                        ? "mdi-coffee-outline"
                        : item.type == "OFFLINE"
                        ? "mdi-home-import-outline"
                        : "mdi-briefcase-clock-outline"
                    }}</v-icon
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.createdAt | formatDateShort }}
                    {{ " | " + $t("worklogRequestStatus." + item.status) }}
                    {{ " | " + $t("worklogRequestType." + item.type) }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >{{ item.driverFirstName }}
                    {{ " " + item.driverLastName }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click.stop="workShiftRequestListDialog = false"
            >{{ $t("btnClose") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmPbxCallDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("ConsoleOrderDetail.confirmPbxCallDialog_title")
          }}</span>
        </v-card-title>
        <v-card-text>
          <span>{{ $t("ConsoleOrderDetail.confirmPbxCallDialog_text") }}</span>
          <v-divider class="mt-4"></v-divider>
          <v-subheader>{{
            $t("ConsoleOrderDetail.subheaderPbxCall")
          }}</v-subheader>
          <v-row dense>
            <v-col cols="12">
              <v-btn
                color="success"
                text
                @click="pbxCall(orderDetailData.client.phone)"
                ><v-icon class="mr-2">mdi-phone-incoming-outgoing</v-icon
                ><span>{{ $t("ConsoleOrderDetail.btnPbxCallClient") }}</span>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="warning"
                text
                @click="pbxCall(orderDetailData.driver.phone)"
                ><v-icon class="mr-2">mdi-phone-incoming-outgoing</v-icon
                ><span>{{ $t("ConsoleOrderDetail.btnPbxCallDriver") }}</span>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="info"
                text
                @click="pbxCallDriverClient(orderDetailData.id)"
                :disabled="buttonOrderDriverPhoneDisable"
                ><v-icon class="mr-2">mdi-phone-incoming-outgoing</v-icon
                ><span
                  >{{ $t("ConsoleOrderDetail.btnPbxCallBetweenDriverClient") }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-4"></v-divider>
          <v-subheader>{{
            $t("ConsoleOrderDetail.subheaderPhoneCopy")
          }}</v-subheader>
          <v-row dense>
            <v-col cols="12">
              <v-btn
                color="success"
                text
                @click="copyText(orderDetailData.client.phone)"
              >
                <v-icon class="mr-2">mdi-content-copy</v-icon
                ><span>{{ $t("ConsoleOrderDetail.btnPhoneClientCopy") }}</span>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="warning"
                text
                @click="copyText(orderDetailData.driver.phone)"
                :disabled="buttonOrderDriverPhoneDisable"
              >
                <v-icon class="mr-2">mdi-content-copy</v-icon
                ><span>{{ $t("ConsoleOrderDetail.btnPhoneDriverCopy") }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-4"></v-divider>
          <v-subheader>{{
            $t("ConsoleOrderDetail.subheaderPhoneHref")
          }}</v-subheader>
          <v-row dense>
            <v-col cols="12">
              <div style="color: #4caf50">
                <a
                  class="ml-4"
                  :href="`tel:${
                    orderDetailData.client ? orderDetailData.client.phone : ''
                  }`"
                  style="text-decoration: none; color: inherit"
                >
                  <v-icon class="mr-2" style="color: inherit"
                    >mdi-link-box-variant-outline</v-icon
                  >
                  {{ $t("ConsoleOrderDetail.btnPhoneClientHref") }}
                </a>
              </div>
            </v-col>
            <v-col cols="12" v-show="!buttonOrderDriverPhoneDisable">
              <div style="color: #fb8c00">
                <a
                  class="ml-4"
                  :href="`tel:${
                    orderDetailData.driver ? orderDetailData.driver.phone : ''
                  }`"
                  style="text-decoration: none; color: inherit"
                >
                  <v-icon class="mr-2" style="color: inherit"
                    >mdi-link-box-variant-outline</v-icon
                  >
                  {{ $t("ConsoleOrderDetail.btnPhoneDriverHref") }}
                </a>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click.stop="confirmPbxCallDialog = false"
            >{{ $t("btnClose") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog na prepnutie Silence rezimu vodica -->
    <v-dialog
      v-model="editDriverSilenceDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("ConsoleOrderDetail.editDriverSilenceDialog_title")
          }}</span>
        </v-card-title>
        <v-card-text>
          <span>{{
            $t("ConsoleOrderDetail.editDriverSilenceDialog_text")
          }}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click.stop="updateDriverSilenceApi(true)"
            >{{ $t("ConsoleOrderDetail.btnSilenceOn") }}</v-btn
          >
          <v-btn
            color="info"
            text
            @click.stop="updateDriverSilenceApi(false)"
            >{{ $t("ConsoleOrderDetail.btnSilenceOff") }}</v-btn
          >
          <v-btn
            color="error"
            text
            @click.stop="editDriverSilenceDialog = false"
            >{{ $t("btnClose") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog so zoznamom objednavok pouzivatela-->
    <v-dialog
      v-model="historyClientDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>{{
          $t("ConsoleOrderDetail.historyClientDialog_title")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in clientHistoryData"
                :key="i"
                @click="getOrderById(item.orderId)"
              >
                <v-list-item-icon>
                  <v-icon :color="getStatusColor(item.status)"
                    >mdi-information</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.createdAt | formatDateShort }}
                    {{ " | " + $t("orderStatus." + item.status) }}
                    {{ " | " + $t("orderType." + item.type) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.orderId.slice(0, 8)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click.stop="historyClientDialog = false">{{
            $t("btnClose")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import {
  Status,
  StatusColor,
  StatusColorDarkContrast,
  SourceColor,
  TypeColor,
} from "../enums/OrderEnum";
import AutocompleteDriverRemote from "../components/AutocompleteDriverRemote.vue";
import OrderEdit from "../components/OrderEdit.vue";
import OrderWaypointEdit from "../components/OrderWaypointEdit.vue";
import * as moment from "moment/moment";
import { moduleDefaultSettings } from "../enums/ModuleEnum";

export default {
  name: "OrderDetailPanel",
  components: {
    AutocompleteDriverRemote,
    OrderEdit,
    OrderWaypointEdit,
  },
  props: {
    selectedOrderId: {
      type: String,
      default: "",
    },
    selectedExtension: {
      type: String,
      default: "",
    },
    //workaround na opatovne zobrazenie detailu objednavky
    selectedOrderTrigger: {
      type: Number,
      default: 0,
    },
    selectedDriverId: {
      type: Number,
      default: 0,
    },
    //tak maly workaround, aby sa zobrazila aj identicka hodnota ID vodica
    selectedDriverTrigger: {
      type: Number,
      default: 0,
    },
    passBoundsFromMapToDetail: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      //moment: moment,
      module: moduleDefaultSettings,
      displayedOrderId: "",
      Status,
      StatusColor,
      StatusColorDarkContrast,
      SourceColor,
      TypeColor,
      displayOnlyForAdmin: false,
      passOrderEditTrigger: 0,
      directEditOrder: "",
      oneTimeUseSelectedOrderTrigger: false,
      buttonOrderEditDisable: true,
      buttonOrderWaypointEditDisable: true,
      buttonOrderChatDisable: true,
      buttonOrderSendDisable: true,
      buttonOrderPreferredDriverDisable: true,
      buttonOrderDriverPhoneDisable: true,

      items: [],
      posts: [],
      errors: [],
      //pre rerender komponentu
      componentKey: 0,
      //Zoznam vodicov pre doplnenie mena k logu z automatu
      driverList: [],
      //Zoznam dispečerov na doplnenie mena k info o dispečerovi, ktorý vytvoril objednávku
      dispatcherList: [],

      //Zoznam vodicov
      driverModel: null,
      driverSearchData: {
        query: null,
        type: null,
      },
      //driverAutocompleteValue: null,
      //zaslanie objednavky na vodica
      sendOrderToDriver: {
        driverId: null,
        orderId: null,
      },
      //Pracovne zmeny
      editDriverWorkShiftDialog: false,
      disableDriverWorkShiftBreak: false,
      disableDriverWorkShiftEnd: false,
      //Zoznam otvorenych poziadaviek na pracovnu zmenu
      worklogRequestList: [],
      workShiftRequestListDialog: false,
      //request data pre zobrazenie schvalovanych pracovnych zmien
      getWorklogRequestItemsData: {
        onlyActive: true,
      },
      //Silent
      editDriverSilenceDialog: false,
      updateSilenceDriverData: {
        driverId: null,
        enabled: null,
      },

      //Update preferovaneho vodica u objednavky
      updatePrefferedDriver: {
        preferredDriverId: null,
      },
      sendOrderData: null,

      //orderId: "",
      orderDetailData: {
        id: "",
        status: "UNDEFINED",
        type: "UNDEFINED",
        service: null,
        paymentType: "UNDEFINED",
        source: "UNDEFINED",
        createdAt: null,
        requestedTime: null,
        acceptedAt: null,
        waitingAt: null,
        progressAt: null,
        finishedAt: null,
        interruptedAt: null,
        archivedAt: null,
        acceptedBy: null,
        waitingBy: null,
        progressBy: null,
        finishedBy: null,
        interruptedBy: null,
        clientRating: null,
        driverRating: null,
        currency: null,
        displayPrice: null,
        note: null,
        plannedAt: null,
        pendingBy: null,
        pendingAt: null,
        plannedBy: null,
        locFrom: null,
        locTo: null,
        interruptedFor: null,
        sentArriveNoti: null,
        finalDistance: null,
        finalTime: null,
        waitingTime: null,
        finalDistancePrice: null,
        finalTimePrice: null,
        minimumFare: null,
        startFare: null,
        finalDrivePrice: null,
        finalWaitingPrice: null,
        priceDiscount: null,
        priceExtra: null,
        fareDistance: null,
        fareTime: null,
        priceType: null,
        waitingLimit: null,
        waitingFare: null,
        finalLocFrom: null,
        finalLocTo: null,
        settledAt: null,
        chargedAt: null,
        finalPrice: null,
        companyType: null,
        customerType: null,
        countedDrivePrice: null,
        fixedDrivePrice: null,
        remainingTime: null,
        estimatedFees: null,
        estimatedPaymentFees: null,
        presendTime: null,
        estimatedDiscount: null,
        estimatedDistance: null,
        estimatedPrice: null,
        estimatedPriceDrive: null,
        estimatedPriceExtra: null,
        estimatedPriceWaiting: null,
        estimatedStartFare: null,
        estimatedTime: null,
        infoDiscount: null,
        infoDistance: null,
        infoPrice: null,
        infoPriceDrive: null,
        infoPriceExtra: null,
        infoPriceWaiting: null,
        infoStartFare: null,
        infoTime: null,
        discount: null,
        distance: null,
        price: null,
        priceDrive: null,
        priceExtra: null,
        priceWaiting: null,
        startFare: null,
        time: null,
        waypointList: [
          {
            id: null,
            status: null,
            ordering: null,
            latitude: null,
            longitude: null,
            address: "-",
            type: "BOARD",
            localityId: null,
          },
        ],
        filter: {
          animal: null,
          luggage: null,
          specialTransport: null,
          //autonomous: null,
          //airconditioning: null,
          //eco: null,
          //smoking: null,
          childrenChair: null,
          maxPerson: null,
          paymentTerminal: null,
          //driverRating: null,
        },
        vehicle: {
          id: null,
        },
        account: {
          id: 0,
          name: null,
        },
        client: {
          id: null,
          email: null,
          nickname: null,
          phone: null,
          rating: null,
          rateCount: null,
          rateSum: null,
          driveCounter: null,
        },
        driver: {
          id: null,
        },
        preferredDriver: {
          id: null,
        },
        orderSentLogs: [
          {
            orderId: null,
            createdAt: null,
          },
        ],
        userDispatcherId: null,
        userDispatcherName: null,
      },
      addressIcons: {
        BOARD: "fas fa-street-view",
        DESTINATION: "fas fa-flag-checkered",
        TRANSIT: "mdi-transit-connection-variant",
      },
      //Telefonaty
      confirmPbxCallDialog: false,
      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 3000,
      },
      //Dialog so zoznamom historickych objednavok
      historyClientDialog: false,
      clientHistoryData: null,
    };
  },

  created() {
    /* if (process.browser) {
      window.addEventListener("storage", () => {
        var pushDriverId = localStorage.getItem("pushDriverId") || 0;
        console.log(pushDriverId);
      });
    }*/
    //Nastavenie modulov
    var modules = this.$store.state.module;
    if (modules !== null && modules !== undefined) {
      this.module = modules;
    }
    //console.log("module", this.module);
  },

  mounted() {
    //Nacitanie role podla ktorej sa potom zobrazuju niektore extra polia
    this.getUserRole();
    this.getDriverList();
    this.getDispatcherList();
  },

  computed: {
    //Usporiadanie prejazdovych bodov od nastúpenia, cez prejazdy, po ciel
    orderDetailWaypointList: function () {
      var value = this.orderDetailData.waypointList;
      return value.sort((a, b) => a.ordering - b.ordering);
    },
    //Nastavenia objednavky do jedneho objektu
    orderDetailSettings: function () {
      var value = this.orderDetailData;
      return {
        forcedPickupRadius: value.forcedPickupRadius,
        onlyManualSending: value.onlyManualSending,
        showAllAddresses: value.showAllAddresses,
        showDriverPhoneButton: value.showDriverPhoneButton,
        showPhone: value.showPhone,
        showPrice: value.showPrice,
      };
    },
  },

  watch: {
    /*selectedOrderId: function (newValue, oldValue) {
      //console.log("SOM Z ORDERDETAILPANEL ", newValue);
      this.displayedOrderId = newValue;
      this.getOrderById(newValue);
    },*/
    //workaround na opatovne zobrazenie objednavky
    selectedOrderTrigger: function (newValue, oldValue) {
      //console.log("SOM Z ORDERDETAILPANEL ", newValue);
      this.oneTimeUseSelectedOrderTrigger = true;
      this.displayedOrderId = this.selectedOrderId;
      this.getOrderById(this.selectedOrderId);
    },
    //Tu je problem, ked sa driveModel zmenil z ineho miesta, a opat pride to iste ID z mapy, tak sa nic neudeje
    //selectedDriverId: function (newValue, oldValue) {
    //console.log("watch selectedDriverId", newValue, " old: ", oldValue);
    //this.driverModel = newValue;
    //},
    selectedDriverTrigger: function (newValue, oldValue) {
      //console.log("watch selectedDriverTrigger", newValue, " old: ", oldValue);
      this.driverModel = this.selectedDriverId;
    },
    passBoundsFromMapToDetail: function (newValue) {
      //console.log("passBoundsFromMapToDetail", newValue);
    },
  },

  methods: {
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },
    incrementPassOrderEditTrigger() {
      //increment value of passOrderEditTrigger to force OrderDetail.vue to reload data
      this.passOrderEditTrigger = this.passOrderEditTrigger + 1;
    },

    updateDriverWorkShift(action) {
      //console.log("driverModel: ", this.driverModel),
      //console.log("action: ", action);
      var shiftApiUrl;
      //this.animateProgressBar("start"),
      switch (action) {
        case "breakShiftApprove":
          shiftApiUrl = "/dispatching/driver/worklog/confirm-break-request/";
          break;
        case "breakShiftReject":
          shiftApiUrl = "/dispatching/driver/worklog/decline-break-request/";
          break;
        case "endShiftApprove":
          shiftApiUrl = "/dispatching/driver/worklog/confirm-offline-request/";
          break;
        case "endShiftReject":
          shiftApiUrl = "/dispatching/driver/worklog/decline-offline-request/";
          break;
        default:
          alert(this.$t("ConsoleOrderDetail.msgUpdateWorkShiftMissingData"));
      }
      if (this.driverModel) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + shiftApiUrl + this.driverModel
          )
          .then((response) => {
            var responseData = response.data;
            //this.animateProgressBar("ok"), (this.items = response.data);
            //console.log(response.data);
            //this.orderDetailData = response.data;
            //this.driverSearchData.query = null;
            //this.sendOrderToDriver.driverId = null;
            //this.driverModel = null;
            if (
              responseData.message == "NO_EXISTS_WORKLOG_REQUEST" ||
              responseData.status == "FAILED"
            ) {
              alert(
                this.$t("ConsoleOrderDetail.msgUpdateWorkShiftMissingRequest")
              );
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "ConsoleOrderDetail.msgUpdateStatusError"
              );
              this.snackbar.color = "error";
            } else {
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "ConsoleOrderDetail.msgUpdateStatusSuccess"
              );
              this.snackbar.color = "success";
            }
          })
          .catch((e) => {
            //this.animateProgressBar("error"), this.errors.push(e);
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgResponseError");
            this.snackbar.color = "error";
          });
      } else {
        alert(this.$t("ConsoleOrderDetail.msgDriverNotSelected"));
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    showOrderDetailByIdFromEdit(orderId) {
      //console.log("OrderDetailPanel.vue - showOrderDetailByIdFromEdit: ", orderId);
      this.forceRerender();
      this.$forceUpdate();
      this.displayedOrderId = orderId;
      this.getOrderById(orderId);
    },
    showEditDriverWorkShiftDialog() {
      if (!this.module.worklog) {
        alert(this.$t("msgModuleWorklogNotEnabled"));
        return;
      }
      if (this.driverModel) {
        var worklogRequestList;
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/driver/worklog`,
            {
              params: this.getWorklogRequestItemsData,
            }
          )
          .then((response) => {
            worklogRequestList = response.data;
            //console.log("showEditDriverWorkShiftDialog - worklogRequestList: ", worklogRequestList);
            //check ifthis.driverModel is in worklogRequestList as driverId parameter
            var driverWorklogRequest = worklogRequestList.find(
              (item) =>
                item.driverId == this.driverModel && item.status == "NEW"
            );
            //console.log("showEditDriverWorkShiftDialog - driverWorklogRequest: ", driverWorklogRequest);
            //Ak zaznam existuje, tak ponuknut jeho spracovanie, zobrazit spravne tlacidla v dialogovom okne
            if (driverWorklogRequest) {
              if (driverWorklogRequest.type == "ON_BREAK") {
                this.disableDriverWorkShiftBreak = false;
                this.disableDriverWorkShiftEnd = true;
              } else if (driverWorklogRequest.type == "OFFLINE") {
                this.disableDriverWorkShiftBreak = true;
                this.disableDriverWorkShiftEnd = false;
              } else {
                this.disableDriverWorkShiftBreak = true;
                this.disableDriverWorkShiftEnd = true;
              }
              this.editDriverWorkShiftDialog = true;
            } else {
              alert(this.$t("ConsoleOrderDetail.msgWorkShiftDriverNoRequest"));
            }
          })
          .catch((e) => {
            //this.errors.push(e);
          });

        //this.editDriverWorkShiftDialog = true;
      } else {
        alert(this.$t("ConsoleOrderDetail.msgDriverNotSelected"));
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/driver/worklog`,
            {
              params: this.getWorklogRequestItemsData,
            }
          )
          .then((response) => {
            this.worklogRequestList = response.data;
            if (this.worklogRequestList.length > 0) {
              this.workShiftRequestListDialog = true;
            }
          })
          .catch((e) => {
            //this.errors.push(e);
          });
      }
    },

    getWorklogRequestItems() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/driver/worklog`, {
          params: this.getWorklogRequestItemsData,
        })
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          //this.errors.push(e);
        });
    },

    openHistoryClientDialog() {
      if (!this.orderDetailData) {
        alert(this.$t("ConsoleOrderDetail.msgMissingClient"));
      } else if (!this.orderDetailData.client) {
        alert(this.$t("ConsoleOrderDetail.msgMissingClient"));
      } else if (this.orderDetailData.client.id == null) {
        alert(this.$t("ConsoleOrderDetail.msgMissingClient"));
      } else {
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/client/${this.orderDetailData.client.id}/order-history`
          )
          .then((response) => {
            //Zresetovanie zoznamu objednavok vybrateho klienta
            this.clientHistoryData = null;
            //Vytvorenie zoznamu objednavok vybrateho klienta
            this.clientHistoryData = response.data.data;
            if (this.clientHistoryData.length > 0) {
              this.historyClientDialog = true;
            } else {
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "ConsoleOrderDetail.msgClientHistoryEmpty"
              );
              this.snackbar.color = "error";
            }
          })
          .catch((e) => {
            console.log("e", e);
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgResponseError");
            this.snackbar.color = "error";
          });
      }
    },

    getOrderById(orderId) {
      //console.log(orderId);
      var orderDetailData;
      //this.displayedOrderId = orderId;
      //console.log("changeOrderIdDetail getOrderById: ", this.displayedOrderId);
      this.forceRerender();
      //this.$forceUpdate();
      //(this.orderId = this.selectedOrderId),
      // this.orderId = this.$store.state.consoleOrderId;
      //this.animateProgressBar("start"),
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/order/` + orderId)
        .then((response) => {
          //this.animateProgressBar("ok"), (this.items = response.data);
          //console.log(response.data);
          orderDetailData = response.data;
          //Ak existuje orderDetailData.orderSentLogs v zozname na zaklade driverId doplnit meno a priezvisko vodiča z driverList
          if (orderDetailData.orderSentLogs) {
            orderDetailData.orderSentLogs.forEach((orderSentLog) => {
              var driver = this.driverList.find(
                (driver) => driver.id == orderSentLog.driverId
              );
              if (driver) {
                orderSentLog.firstName = driver.firstName;
                orderSentLog.lastName = driver.lastName;
                orderSentLog.internalName = driver.internalName;
                //console.log("driver: ", driver);
              }
              if (orderSentLog.fromBy == "automat") {
                orderSentLog.fromBy = "Auto";
              }
            });
            //nasledne aktualizovat orderDetailData.orderSentLogs
            //orderDetailData.orderSentLogs = orderDetailData.orderSentLogs;
            //console.log("orderDetailData.orderSentLogs: ",orderDetailData.orderSentLogs);
            //Usporiadat orderDetailData.orderSentLogs podla datumu od najnovsieho po najstarsie
            orderDetailData.orderSentLogs.sort(function (a, b) {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
          }
          //ak je objednávka z dispečingu, doplniť meno a priezvisko dispečera do userDispatcherName zo zoznamu dispatcherList
          if (orderDetailData.userDispatcherId) {
            var dispatcher = this.dispatcherList.find(
              (dispatcher) => dispatcher.id == orderDetailData.userDispatcherId
            );
            if (dispatcher) {
              orderDetailData.userDispatcherName =
                dispatcher.firstName + " " + dispatcher.lastName;
            }
          }

          this.orderDetailData = orderDetailData;
          var orderStatus = this.orderDetailData.status;
          //this.driverSearchData.query = null;
          //this.sendOrderToDriver.driverId = null;
          //console.log("orderStatus: ", orderStatus);
          //Change format of date plannedAt pendingAt acceptedAt waitingAt  progressAt finishedAt
          //interruptedAt archivedAt   settledAt chargedAt to "DD.MM.YYYY HH:mm:ss" with moment.js
          const dateFormat = "DD.MM.YYYY HH:mm:ss";
          const elseDateText = null;
          this.orderDetailData.plannedAt = this.orderDetailData.plannedAt
            ? moment(this.orderDetailData.plannedAt).format(dateFormat)
            : elseDateText;
          this.orderDetailData.pendingAt = this.orderDetailData.pendingAt
            ? moment(this.orderDetailData.pendingAt).format(dateFormat)
            : elseDateText;
          this.orderDetailData.acceptedAt = this.orderDetailData.acceptedAt
            ? moment(this.orderDetailData.acceptedAt).format(dateFormat)
            : elseDateText;
          this.orderDetailData.waitingAt = this.orderDetailData.waitingAt
            ? moment(this.orderDetailData.waitingAt).format(dateFormat)
            : elseDateText;
          this.orderDetailData.progressAt = this.orderDetailData.progressAt
            ? moment(this.orderDetailData.progressAt).format(dateFormat)
            : elseDateText;
          this.orderDetailData.finishedAt = this.orderDetailData.finishedAt
            ? moment(this.orderDetailData.finishedAt).format(dateFormat)
            : elseDateText;
          this.orderDetailData.interruptedAt = this.orderDetailData
            .interruptedAt
            ? moment(this.orderDetailData.interruptedAt).format(dateFormat)
            : elseDateText;
          this.orderDetailData.archivedAt = this.orderDetailData.archivedAt
            ? moment(this.orderDetailData.archivedAt).format(dateFormat)
            : elseDateText;
          this.orderDetailData.settledAt = this.orderDetailData.settledAt
            ? moment(this.orderDetailData.settledAt).format(dateFormat)
            : elseDateText;
          this.orderDetailData.chargedAt = this.orderDetailData.chargedAt
            ? moment(this.orderDetailData.chargedAt).format(dateFormat)
            : elseDateText;

          //Ak existuje preferovany vodic tak ho predvolit, ak je uz priradeny vodic, tak predvolit jeho - aj podla stavu objednavky
          if (
            response.data.preferredDriver &&
            (orderStatus == "NEW" ||
              orderStatus == "PLANNED" ||
              orderStatus == "PENDING")
          ) {
            if (response.data.preferredDriver.id) {
              this.driverModel = response.data.preferredDriver.id;
            } else {
              this.driverModel = null;
            }
          } else if (
            response.data.driver &&
            (orderStatus == "ACCEPTED" ||
              orderStatus == "WAITING" ||
              orderStatus == "IN_PROGRESS")
          ) {
            if (response.data.driver.id) {
              this.driverModel = response.data.driver.id;
            } else {
              this.driverModel = null;
            }
          } else {
            this.driverModel = null;
          }

          //Skryvanie tlacidiel na zmenu na zaklade STAVOV objednavky
          if (orderStatus == "PREREGISTERED") {
            this.buttonOrderEditDisable = false;
            this.buttonOrderWaypointEditDisable = false;
            this.buttonOrderChatDisable = true;
            this.buttonOrderSendDisable = true;
            this.buttonOrderPreferredDriverDisable = false;
            this.buttonOrderDriverPhoneDisable = true;
          } else if (orderStatus == "TRANSFERED") {
            this.buttonOrderEditDisable = false;
            this.buttonOrderWaypointEditDisable = false;
            this.buttonOrderChatDisable = true;
            this.buttonOrderSendDisable = true;
            this.buttonOrderPreferredDriverDisable = false;
            this.buttonOrderDriverPhoneDisable = true;
          } else if (orderStatus == "NEW") {
            this.buttonOrderEditDisable = false;
            this.buttonOrderWaypointEditDisable = false;
            this.buttonOrderChatDisable = true;
            this.buttonOrderSendDisable = true;
            this.buttonOrderPreferredDriverDisable = false;
            this.buttonOrderDriverPhoneDisable = true;
            //console.log("oneTimeUseSelectedOrderTrigger: ",this.oneTimeUseSelectedOrderTrigger);
            //ak je objednavka v stave NEW a je to prvy krat (teda zo zoznamu objednavok), tak sa zobrazi okno na editaciu
            if (this.oneTimeUseSelectedOrderTrigger == true) {
              this.directEditOrder = "confirmNew";
              this.incrementPassOrderEditTrigger();
            }
          } else if (orderStatus == "PLANNED") {
            this.buttonOrderEditDisable = false;
            this.buttonOrderWaypointEditDisable = false;
            this.buttonOrderChatDisable = true;
            this.buttonOrderSendDisable = true;
            this.buttonOrderPreferredDriverDisable = false;
            this.buttonOrderDriverPhoneDisable = true;
          } else if (orderStatus == "PENDING") {
            this.buttonOrderEditDisable = false;
            this.buttonOrderWaypointEditDisable = false;
            this.buttonOrderChatDisable = true;
            this.buttonOrderSendDisable = false;
            this.buttonOrderPreferredDriverDisable = false;
            this.buttonOrderDriverPhoneDisable = true;
          } else if (orderStatus == "ACCEPTED") {
            this.buttonOrderEditDisable = false;
            this.buttonOrderWaypointEditDisable = true;
            this.buttonOrderChatDisable = false;
            this.buttonOrderSendDisable = true;
            this.buttonOrderPreferredDriverDisable = true;
            this.buttonOrderDriverPhoneDisable = false;
          } else if (orderStatus == "WAITING") {
            this.buttonOrderEditDisable = false;
            this.buttonOrderWaypointEditDisable = true;
            this.buttonOrderChatDisable = false;
            this.buttonOrderSendDisable = true;
            this.buttonOrderPreferredDriverDisable = true;
            this.buttonOrderDriverPhoneDisable = false;
          } else if (orderStatus == "IN_PROGRESS") {
            this.buttonOrderEditDisable = false;
            this.buttonOrderWaypointEditDisable = true;
            this.buttonOrderChatDisable = false;
            this.buttonOrderSendDisable = true;
            this.buttonOrderPreferredDriverDisable = true;
            this.buttonOrderDriverPhoneDisable = false;
          } else if (
            orderStatus == "FINISHED" ||
            orderStatus == "CHARGED" ||
            orderStatus == "SETTLED" ||
            orderStatus == "ARCHIVED" ||
            orderStatus == "CANCELLED_PREREGISTERED" ||
            orderStatus == "CANCELLED_NEW" ||
            orderStatus == "CANCELLED_PLANNED" ||
            orderStatus == "CANCELLED_PENDING" ||
            orderStatus == "CANCELLED_ACCEPTED" ||
            orderStatus == "CANCELLED_WAITING" ||
            orderStatus == "CANCELLED_IN_PROGRESS" ||
            orderStatus == "CANCELLED_FINISHED" ||
            orderStatus == "CANCELLED_CHARGED" ||
            orderStatus == "CANCELLED_SETTLED"
          ) {
            this.buttonOrderEditDisable = true;
            this.buttonOrderWaypointEditDisable = true;
            this.buttonOrderChatDisable = false; //bolo true, ale potrebuju vidiet chat aj uzavretych objednavok
            this.buttonOrderSendDisable = true;
            this.buttonOrderPreferredDriverDisable = true;
            this.buttonOrderDriverPhoneDisable = true;
          } else {
            console.log("Blokovanie tlacidiel nesplna podmienky stavov");
            this.buttonOrderEditDisable = true;
            this.buttonOrderWaypointEditDisable = true;
            this.buttonOrderChatDisable = true;
            this.buttonOrderSendDisable = true;
            this.buttonOrderPreferredDriverDisable = true;
            this.buttonOrderDriverPhoneDisable = true;
          }
          this.oneTimeUseSelectedOrderTrigger = false;
        })
        .catch((e) => {
          //this.animateProgressBar("error"), this.errors.push(e);
          this.snackbar.show = "true";
          this.snackbar.message = this.$t(
            "ConsoleOrderDetail.msgGetOrderDetailError"
          );
          this.snackbar.color = "error";
          this.oneTimeUseSelectedOrderTrigger = false;
        });
    },
    animateProgressBar(progressStatus) {
      if (progressStatus == "start") {
        this.loading = true;
        this.loadingIndeterminate = false;
      } else if (progressStatus == "ok") {
        this.loading = false;
        this.loadingIndeterminate = false;
      } else if (progressStatus == "error") {
        //v pripade chyby sa prepne do rezimu animovaneho loadingu, kym sa neuskotocni uspesny pokus o ziskanie udajov
        this.loadingIndeterminate = true;
      }
    },
    getStatusColor(status) {
      return this.StatusColor[status];
    },
    getStatusColorDark(status) {
      if (status == undefined) {
        return this.StatusColorDarkContrast["NEW"];
      } else {
        return this.StatusColorDarkContrast[status];
      }
    },
    getTypeColor(type) {
      return this.TypeColor[type];
    },
    getSourceColor(source) {
      return this.SourceColor[source];
    },
    copyText(textToCopy) {
      const el = document.createElement("textarea");
      el.value = textToCopy;
      el.setAttribute("readonly", "");
      el.style.top = "0";
      el.style.left = "0";
      el.style.position = "fixed";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.snackbar.show = "true";
      this.snackbar.message = this.$t("ConsoleOrderDetail.msgCopyText");
      this.snackbar.color = "success";
    },
    pbxCall(phoneNumber) {
      if (phoneNumber == null || phoneNumber == "") {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "ConsoleOrderDetail.msgPbxCallNumberMissing"
        );
        this.snackbar.color = "warning";
        return;
      }
      if (this.selectedExtension == null || this.selectedExtension == "") {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "ConsoleOrderDetail.msgPbxCallLineMissing"
        );
        this.snackbar.color = "warning";
        return;
      }
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/pbx`, {
          params: {
            caller: this.selectedExtension,
            callee: phoneNumber,
          },
        })
        .then((response) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t(
            "ConsoleOrderDetail.msgPbxCallSuccess"
          );
          this.snackbar.color = "success";
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("ConsoleOrderDetail.msgPbxCallError");
          this.snackbar.color = "error";
        });
    },
    pbxCallDriverClient(orderId) {
      if (orderId == null || orderId == "") {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "ConsoleOrderDetail.msgPbxCallOrderMissing"
        );
        this.snackbar.color = "warning";
        return;
      }
      var driverId = this.orderDetailData.driver.id;
      console.log("pbxCallDriverClient - driverId: " + driverId);
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/order/` +
            orderId +
            `/pbx`
          // , { params: { orderId: orderId, }, }
        )
        .then((response) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t(
            "ConsoleOrderDetail.msgPbxCallSuccess"
          );
          this.snackbar.color = "success";
          //vytvorenie chat spravy pre vodica, ze mu dispecer vytvoril hovor so zakaznikom
          var messageText = this.$t(
            "ConsoleOrderDetail.chatMsgSuccessPbxDriverClientCall"
          );
          this.sendDispDriverMessage(driverId, messageText);
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("ConsoleOrderDetail.msgPbxCallError");
          this.snackbar.color = "error";
        });
    },
    sendDispDriverMessage(driverId, messageText) {
      if (driverId && driverId != null && messageText && messageText != null) {
        var sendDispDriverData = {
          message: messageText,
          driverId: driverId,
        };
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/message/dispdriver`,
            sendDispDriverData
          )
          .then((response) => {
            //this.animateProgressBar("ok"), (this.items = response.data);
          })
          .catch((e) => {
            //this.animateProgressBar("error"), this.errors.push(e);
            //  this.createClientDialog = true;
          });
      } else {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "ConsoleOrderMessage.sendDispDriverMessageError"
        );
        this.snackbar.color = "error";
      }
    },
    sendOrderToDriverApi() {
      if (this.driverModel == null) {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "ConsoleOrderDetail.msgSendOrderDriverNotSelected"
        );
        this.snackbar.color = "warning";
        return;
      }
      if (this.orderDetailData.id == null) {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "ConsoleOrderDetail.msgSendOrderOrderNotSelected"
        );
        this.snackbar.color = "warning";
        return;
      }
      //upozornit, že id vodiča je ine ako preferovany vodic
      if (this.orderDetailData.preferredDriver != null) {
        if (
          this.driverModel != null &&
          this.driverModel != "" &&
          this.orderDetailData.preferredDriver.id != null &&
          this.driverModel != this.orderDetailData.preferredDriver.id
        ) {
          //Alert okno s potvrdenim, inak "return;"
          let notPreferredWarningText = this.$t(
            "ConsoleOrderDetail.msgSendOrderDriverNotPreferred"
          );
          if (confirm(notPreferredWarningText) == true) {
            console.log("sendOrderToDriverApi - confirm non preferred driver");
          } else {
            return;
          }
        }
      }
      //console.log(OrderStatus.NEW),
      //this.animateProgressBar("start"),
      //console.log("driverModel", this.driverModel);
      this.sendOrderToDriver.driverId = this.driverModel;
      this.sendOrderToDriver.orderId = this.orderDetailData.id;
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/order/directSend`,
          this.sendOrderToDriver
        )
        .then((response) => {
          //this.animateProgressBar("ok"), (this.items = response.data);
          if (response.data.status == "OK") {
            //this.sendOrderData = response.data.data;
            // getClientByPhone();
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "ConsoleOrderDetail.msgSendOrderSuccess"
            );
            this.snackbar.color = "success";
            this.createClientDialog = false;
          } else if (response.data.status == "FAILED") {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "ConsoleOrderDetail.msgSendOrderError"
            );
            this.snackbar.color = "error";
          } else {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "ConsoleOrderDetail.msgSendOrderError"
            );
            this.snackbar.color = "error";
          }
        })
        .catch((e) => {
          //this.animateProgressBar("error"), this.errors.push(e);
        });
    },
    updatePreferredDriverApi() {
      //console.log(OrderStatus.NEW),
      //this.animateProgressBar("start"),
      this.updatePrefferedDriver.preferredDriverId = this.driverModel;
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/order/` +
            this.orderDetailData.id +
            `/preferredDriver`,
          this.updatePrefferedDriver
        )
        .then((response) => {
          //this.animateProgressBar("ok"), (this.items = response.data);
          if (response.data.status == "OK") {
            //this.sendOrderData = response.data.data;
            // getClientByPhone();
            this.createClientDialog = false;
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "ConsoleOrderDetail.msgPreferredDriverSetSuccess"
            );
            this.snackbar.color = "success";
          } else if (response.data.status == "FAILED") {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "ConsoleOrderDetail.msgPreferredDriverSetError"
            );
            this.snackbar.color = "error";
          } else {
            //
          }
        })
        .catch((e) => {
          //this.animateProgressBar("error"), this.errors.push(e);
          this.snackbar.show = "true";
          this.snackbar.message = this.$t(
            "ConsoleOrderDetail.msgPreferredDriverSetError"
          );
          this.snackbar.color = "error";
        });
    },
    changeDriverSilence() {
      if (this.driverModel) {
        this.editDriverSilenceDialog = true;
      } else {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "ConsoleOrderDetail.msgSendOrderDriverNotSelected"
        );
        this.snackbar.color = "warning";
      }
    },
    updateDriverSilenceApi(value) {
      if (!this.driverModel) {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "ConsoleOrderDetail.msgSendOrderDriverNotSelected"
        );
        this.snackbar.color = "warning";
      } else {
        this.updateSilenceDriverData.driverId = this.driverModel;
        this.updateSilenceDriverData.enabled = value;
      }
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/driver/silence`,
          this.updateSilenceDriverData
        )
        .then((response) => {
          //this.animateProgressBar("ok"), (this.items = response.data);
          if (value == true) {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "ConsoleOrderDetail.msgSilenceOnSuccess"
            );
            this.snackbar.color = "success";
          } else if (value == false) {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "ConsoleOrderDetail.msgSilenceOffSuccess"
            );
            this.snackbar.color = "success";
          }
          this.editDriverSilenceDialog = false;
        })
        .catch((e) => {
          //this.animateProgressBar("error"), this.errors.push(e);
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("ConsoleOrderDetail.msgSilenceError");
          this.snackbar.color = "error";
        });
    },
    findDriverOnMap() {
      //if this.driveModel not set, than show snackbar
      if (this.driverModel) {
        this.$emit("passFindDriverIdToMap", this.driverModel);
      } else {
        this.$emit("passFindDriverIdToMap", this.driverModel);
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "ConsoleOrderDetail.msgFindDriverNotSet"
        );
        this.snackbar.color = "warning";
      }
    },
    findPositionOnMap(latitude, longitude) {
      if (latitude && longitude) {
        var positionCoordinates = [];
        positionCoordinates.push({
          latitude: latitude,
          longitude: longitude,
        });
        this.$emit("passFindPositionCoordinatesToMap", positionCoordinates);
      } else {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "ConsoleOrderDetail.msgFindPositionError"
        );
        this.snackbar.color = "error";
      }
    },
    showMessagefromOrderDetail(orderId) {
      this.$emit("showMessagefromOrderDetail", orderId);
      //this.$store.state.consoleOrderId = orderId;
    },
    notifyMessageStatus(orderStatus) {
      if (
        orderStatus == "FINISHED" ||
        orderStatus == "CHARGED" ||
        orderStatus == "SETTLED" ||
        orderStatus == "ARCHIVED" ||
        orderStatus == "CANCELLED_PREREGISTERED" ||
        orderStatus == "CANCELLED_NEW" ||
        orderStatus == "CANCELLED_PLANNED" ||
        orderStatus == "CANCELLED_PENDING" ||
        orderStatus == "CANCELLED_ACCEPTED" ||
        orderStatus == "CANCELLED_WAITING" ||
        orderStatus == "CANCELLED_IN_PROGRESS" ||
        orderStatus == "CANCELLED_FINISHED" ||
        orderStatus == "CANCELLED_CHARGED" ||
        orderStatus == "CANCELLED_SETTLED"
      ) {
        alert(this.$t("ConsoleOrderDetail.msgMessageStatusCannotSend"));
      }
    },
    getDriverList() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/driver/forPicker?all=true`
        )
        .then((res) => {
          //console.log(res);
          this.driverList = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    getDispatcherList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/dispatcher`)
        .then((response) => {
          //remove from response data objects with role ADMIN and SUPPORT
          if (this.displayOnlyForAdmin) {
            this.dispatcherList = response.data;
          } else {
            this.dispatcherList = response.data.filter(
              (item) => item.role !== "ADMIN" && item.role !== "SUPPORT"
            );
          }
        })
        .catch((e) => {
          console.log("error loading dispatcher list dat" + e);
        });
    },
  },
};
</script>
